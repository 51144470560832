
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient, FootwearEstimationClient, GarmentClient, TransportDistanceCalculationClient } from '@/services/Services';
import ProcessTransport from './components/processTransport.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ProcessTransport
    }
})
export default class ProcessesLocationsAndTransports extends Vue {

    estimationIdentifier: string = "";
    footwearData: OM.FootwearData = new OM.FootwearData();
    processes: OM.FootwearProcess[] = [];

    electricityCountries: OM.TextIdentifier[] = [];
    transportationCountries: OM.TransportDistanceCalculationVM[] = [];
    
    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.init();
    }

    init() {
        Promise.all([
            FootwearEstimationClient.getEstimationFootwearData(this.estimationIdentifier),
            ElectricityEmissionClient.getCountries(true),
            TransportDistanceCalculationClient.getAllConfigurations(),
        ])
        .then(xs => {
            this.footwearData = xs[0];
            this.electricityCountries = xs[1];
            this.transportationCountries = xs[2];

            GarmentClient.getAllProcessesByFootwearTypeAndProcessType(this.footwearData.garment.identifier, this.footwearData.footwearType, this.footwearData.processType)
            .then(y => {
                this.processes = y;
            })
        })
    }

    getPrevProcess(actualProcessName: string) {
        var findIndex = this.processes.findIndex(x => x.name == actualProcessName);

        if(findIndex > 0)
            return this.processes[findIndex -1].name;
        else
            return "";
    }

    get isDisabled() {
        var alreadyDoneCount = this.footwearData.processesCo2eEmissionsAndTransports.length;
        return alreadyDoneCount < this.processes.length;
    }
}
